import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "@arc-fusion/prop-types";
import { useContent } from "fusion-content";
import { useAppContext } from "fusion-context";
import get from "lodash.get";
import {
  INIT_DEDUPE_INFO,
  checkIsSuppressibleDuplicate,
  HandleSuppressibleDuplicateInAdmin
} from "~/components/utilities/handle-duplicate-content";
import { getAncillaryContentConfig } from "../default.helpers";
import {
  hasBackingContent,
  hasCustomContentTantamountToContent,
  adjustOverrides,
  getPromoImage,
  getArtSlotInfo
} from "../utilities/index";
import StoryCard from "~/shared-components/story-card/default";
import { MAX_SLIDES } from "~/shared-components/story-card/_children/Slideshow.helpers";
// NOTE: For-deduping
import { useRenderedContentContext } from "~/components/contexts/rendered-content-context";
import { useChainContext } from "~/shared-components/ChainContext";
import { useTableLayoutContext } from "~/shared-components/TableLayoutContext";
import { useSandwichLayoutContext } from "~/shared-components/layouts/SandwichLayoutContext";
import GridItem from "~/components/layouts/utilities/grid-item.jsx";
import { transform as anglerfishTransform } from "~/content/helpers/transform/transform-anglerfish";
import { HandleFetchErrors } from "../utilities/handle-fetch-errors";
import { isFeatureHiddenOnMobile } from "~/shared-components/story-card/_utilities/helpers";
import { getRTEChildAttrs } from "../../utilities/rte";

/**
 * @param {object} fetchedContent Content fetched from contentConfig
 * @param {number} index Number of story in the feed, used for the React key.
 * @param {object} overrides The customFields of the feature.
 * @returns {component} the StoryCard component rended with the content and rendering information.
 */
const StoryCards = ({ overrides: initialOverrides, ...props }) => {
  const {
    noGrid,
    index,
    fetchedContent,
    isAdmin,
    isFeed,
    curationIndices,
    rootCustomFields
  } = props;

  const { disableLazyLoading } = useChainContext() || {};

  const overrides = useMemo(() => {
    // TODO: Get as much art business logic out of here as possible
    // and into code that can be re-used by jsonapp
    // Adjust overrides for certain special cases
    return adjustOverrides(JSON.parse(JSON.stringify(initialOverrides)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialOverrides)]);

  const { showFeatureName } = useSandwichLayoutContext();
  const { siteProperties, outputType } = useAppContext();

  // use this to toggle off lazy loading in story cards for the chain
  overrides.disableLazyLoading =
    disableLazyLoading || siteProperties.disableLazyLoading;

  const ancillaryContentConfig = getAncillaryContentConfig({
    overrides,
    isAdmin
  });

  const noContent = useContent(ancillaryContentConfig.noContent);

  const thereIsBackingContent = hasBackingContent(fetchedContent);
  const thereIsCustomContentTantamountToContent =
    thereIsBackingContent ||
    hasCustomContentTantamountToContent(overrides, noContent);
  // NOTE: Get this into overrides so it can be used downstream, like in <Sigline />
  overrides.thereIsBackingContent = thereIsBackingContent;
  overrides.thereIsCustomContentTantamountToContent =
    thereIsCustomContentTantamountToContent;

  const content = thereIsBackingContent ? fetchedContent : noContent;

  // NOTE: fetch podcast -- it would be nice if less defensive code were necessary, but this works
  const podcastConfig =
    ancillaryContentConfig.podcastData || ancillaryContentConfig.noConfig;
  let podcastData = useContent(podcastConfig);
  podcastData =
    podcastConfig === ancillaryContentConfig.noConfig ? undefined : podcastData;
  // END: fetch podcast

  // START: fetch video -- it would be nice if less defensive code were necessary, but this works
  const videoConfig =
    ancillaryContentConfig.videoData || ancillaryContentConfig.noConfig;
  let videoData = useContent(videoConfig);
  videoData =
    videoConfig === ancillaryContentConfig.noConfig ? undefined : videoData;
  // END: fetch video

  // START: fetch election -- it would be nice if less defensive code were necessary, but this works
  const electionConfig =
    ancillaryContentConfig.electionData || ancillaryContentConfig.noConfig;
  let electionData = useContent(electionConfig);
  electionData =
    electionConfig === ancillaryContentConfig.noConfig
      ? undefined
      : electionData;
  // END: fetch election

  const {
    alternateArt,
    artSize,
    artPosition,
    artAspectRatio,
    liveGraphicsContentConfig,
    coverArtUrl
  } = overrides;

  const { url: art, provenance } = getPromoImage({ content, overrides });

  // START: fetch olympics -- it would be nice if less defensive code were necessary, but this works
  const olympicsConfig =
    ancillaryContentConfig.olympicsData || ancillaryContentConfig.noConfig;
  let olympicsData = useContent(olympicsConfig);
  olympicsData =
    olympicsConfig === ancillaryContentConfig.noConfig
      ? undefined
      : olympicsData;
  // END: fetch olympics

  // START: fetch image -- it would be nice if less defensive code were necessary, but this works
  const imageConfig =
    ancillaryContentConfig.imageData || ancillaryContentConfig.noConfig;
  let imageData = useContent({
    ...imageConfig,
    transform: anglerfishTransform // runs the transform in Assembler
  });
  imageData =
    imageConfig === ancillaryContentConfig.noConfig ? undefined : imageData;
  // END: fetch image

  // START: fetch slideshow -- it would be nice if less defensive code were necessary, but this works
  const slideshowListOfConfig = (
    ancillaryContentConfig.slideshowListOfData || []
  )
    // NOTE: rule-of-hooks safety, Part I -- Filter length to MAX_SLIDES
    .filter((_, i) => i < MAX_SLIDES);
  // NOTE: rule-of-hooks safety, Part II -- Fill length to MAX_SLIDES
  if (slideshowListOfConfig.length < MAX_SLIDES) {
    slideshowListOfConfig.push(
      ...new Array(MAX_SLIDES - slideshowListOfConfig.length).fill(
        ancillaryContentConfig.noConfig
      )
    );
  }
  const slideshowListOfData = slideshowListOfConfig.map((config) => {
    // NOTE: Safely disabling rule-of-hooks (see above)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    let data = useContent({
      ...config,
      transform: anglerfishTransform // runs the transform in Assembler
    });
    data = config === ancillaryContentConfig.noConfig ? undefined : data;
    return data;
  });
  // END: fetch slideshow

  // NOTE: fetch liveTicker -- it would be nice if less defensive code were necessary, but this works
  const liveTickerConfig =
    ancillaryContentConfig.liveTickerData || ancillaryContentConfig.noConfig;
  let liveTickerData = useContent(liveTickerConfig);
  liveTickerData =
    liveTickerConfig === ancillaryContentConfig.noConfig
      ? undefined
      : liveTickerData;
  // END: fetch liveTicker

  // NOTE: get art slot info
  const artSlot = useMemo(() => {
    return getArtSlotInfo({ content, overrides });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    alternateArt,
    artSize,
    artPosition,
    artAspectRatio,
    liveGraphicsContentConfig,
    coverArtUrl,
    art,
    provenance
  ]);

  const isLoading = get(fetchedContent, "isLoading", false);
  // NOTE: Needed for client-side content sources
  const [isReadyToUpdate, setReadyToUpdate] = useState(!isLoading);

  // START: Handle suppressible duplicates
  const { renderedContent } = useRenderedContentContext();
  const chainOverrides = useChainContext();
  const { useDesktopOrdering } = chainOverrides;
  const [dedupeInfo, setDedupeInfo] = React.useState(INIT_DEDUPE_INFO);
  const { desktopInfo, mobileInfo } = dedupeInfo;
  useEffect(() => {
    setReadyToUpdate(true);
    setDedupeInfo(
      checkIsSuppressibleDuplicate({
        renderedContent,
        rootCustomFields,
        chainOverrides,
        curationIndices,
        // featureId: id,
        content,
        overrides
      })
    );
  }, [
    renderedContent,
    rootCustomFields,
    chainOverrides,
    curationIndices,
    // featureId: id,
    content,
    overrides
  ]);
  const isSuppressibleDuplicate = (
    useDesktopOrdering ? desktopInfo : mobileInfo
  ).isSuppressibleDuplicate;
  // END: Handle suppressible duplicates

  const { updateItemsPerFeatureMapDQedAdjustments, featureId } =
    useTableLayoutContext();

  // START: doNotShow
  const doNotShow = useMemo(() => {
    // NOTE: Duplicate items in feeds handled by Feed.jsx
    // TOOD: isFeed checks may be superfluous. Test w/o them
    if (isAdmin || isFeed) return false;
    return (
      isSuppressibleDuplicate ||
      !(thereIsBackingContent || thereIsCustomContentTantamountToContent)
    );
  }, [
    isAdmin,
    isSuppressibleDuplicate,
    thereIsBackingContent,
    thereIsCustomContentTantamountToContent,
    isFeed
  ]);
  // END: doNotShow

  // NOTE: If doNotShow need to update the data tracking how many grid items
  // per feature to show. map from 0 to -1.
  useEffect(() => {
    // NOTE: In the admin, don't let the items per feature go 0 otherwise
    // the feature won't come back unless the admin is reloaded
    const adjustment = doNotShow ? -1 : 0;
    if (isReadyToUpdate && updateItemsPerFeatureMapDQedAdjustments) {
      updateItemsPerFeatureMapDQedAdjustments(featureId, adjustment);
    }
  }, [
    isReadyToUpdate,
    featureId,
    doNotShow,
    updateItemsPerFeatureMapDQedAdjustments
  ]);

  // NOTE: When published (doNotShow is always false in the admin), exclude component entirely
  if (doNotShow) return null;

  const customHTML = artSlot.isHtml ? artSlot.raw.alternateArt : undefined;
  const text = (
    <React.Fragment>
      <span style={{ color: "red" }}>Hidden: </span>
    </React.Fragment>
  );

  const GridContent = (
    <HandleSuppressibleDuplicateInAdmin
      isAdmin={isAdmin}
      isSuppressibleDuplicate={isSuppressibleDuplicate}
    >
      {showFeatureName && (
        <span
          style={{
            marginBottom: "8px",
            display: "block",
            opacity: 0.75
          }}
        >
          {isFeatureHiddenOnMobile(initialOverrides, outputType) ? text : ""}
          {overrides?.displayName || "fronts/flex-feature"}
        </span>
      )}
      {isAdmin && (
        <HandleFetchErrors
          requireContent={true}
          content={fetchedContent}
          configs={ancillaryContentConfig}
          data={{
            imageData,
            videoData,
            slideshowListOfData,
            podcastData,
            liveTickerData,
            electionData,
            olympicsData
          }}
          lists={{
            slideshow: {
              listOfConfigs: slideshowListOfConfig,
              listOfData: slideshowListOfData
            }
          }}
        />
      )}
      <StoryCard
        key={index}
        configs={ancillaryContentConfig}
        content={content}
        overrides={overrides}
        imageData={imageData}
        slideshowListOfData={slideshowListOfData}
        videoData={videoData}
        podcastData={podcastData}
        liveTickerData={liveTickerData}
        electionData={electionData}
        olympicsData={olympicsData}
        customHTML={customHTML}
        artSlot={artSlot}
        {...props}
      />
    </HandleSuppressibleDuplicateInAdmin>
  );

  return noGrid ? (
    GridContent
  ) : (
    <GridItem
      index={index}
      overrides={overrides}
      featureLabel="The Flex Feature"
      placementRequirements={{
        inMultiTableChain: true,
        illegalContent: ["prism-placeholder-feed"]
      }}
      attrs={getRTEChildAttrs(content)}
    >
      {GridContent}
    </GridItem>
  );
};

StoryCards.propTypes = {
  noGrid: PropTypes.bool,
  id: PropTypes.string,
  index: PropTypes.number,
  fetchedContent: PropTypes.object,
  rootCustomFields: PropTypes.object,
  overrides: PropTypes.object,
  curationIndices: PropTypes.object,
  isAdmin: PropTypes.bool,
  isFeed: PropTypes.bool
};

export default React.memo(StoryCards);
