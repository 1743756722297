import React, { useState } from "react";
import get from "lodash.get";
import { useContent } from "fusion-content";
import { useFusionContext } from "fusion-context";
import { useChainContext } from "~/shared-components/ChainContext";
import { useSandwichLayoutContext } from "~/shared-components/layouts/SandwichLayoutContext";
import { useBreakpoints } from "~/shared-components/BreakpointContext";
import { usePersonalizedContentContext } from "~/components/contexts/personalized-content-context";
import { applyMobilePresets } from "~/components/features/fronts/flex-feature/utilities/mobile-presets";
import useApi from "~/components/utilities/use-api";
import StoryCards from "./_children/StoryCards";
import { feedType } from "./utilities/index";
import defaults from "./utilities/custom-field-defaults";
import Feed from "./_children/Feed";
import { noContentConfig } from "./default.helpers";
import { FlexFeaturePropTypes } from "~/proptypes/flex-feature";
import {
  getSecureUserId,
  getUserId
} from "~/components/utilities/login-details";
import {
  getRTEModuleCategory,
  getRTEModulePosition,
  getRTEParentAttrs,
  getRTESurface
} from "~/components/features/fronts/utilities/rte";
import { useTableLayoutContext } from "~/shared-components/TableLayoutContext";

/**
 * Flex Feature
 * @param {string} id
 * @param {object} customFields
 * @param {bool} noGrid
 * @param {object} context
 * @return {Component}
 */
const FlexFeature = (props) => {
  const [isServerSide, setServerSide] = useState(true);
  const { noGrid, id, context, curationIndices, rootCustomFields } = props;
  let { customFields } = props;
  const layoutCtx = useSandwichLayoutContext();
  const chainCtx = useChainContext() || {};
  const { registerForYouSurface, isReady: isPersonalizedContentReady } =
    usePersonalizedContentContext();

  const { bp } = useBreakpoints();

  customFields = applyMobilePresets({
    customFields: { ...defaults, ...customFields },
    chainCtx
  });

  const { flexFeatureContentConfig } = customFields;

  const fusionContext = useFusionContext();

  const { isAdmin, metaValue = () => {} } =
    context !== undefined && context.isAdmin !== undefined
      ? context
      : fusionContext;
  const overrides = customFields;

  const [userId, setUserId] = useState();
  const [secureUserId, setSecureUserId] = useState();
  React.useEffect(() => {
    setServerSide(false);
    setUserId(getUserId());
    setSecureUserId(getSecureUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * START: Set Real-time Events (RTE) attributes (Part I) on the parent table.
   */
  const modulePosition = getRTEModulePosition(layoutCtx, curationIndices);
  const surface = getRTESurface(
    context,
    modulePosition,
    get(overrides, "flexFeatureContentConfig.contentConfigValues", {})
  );
  // END: Set Real-time Events (RTE)

  const { type, contentConfig, clientSideConfig } = feedType({
    flexFeatureContentConfig,
    userId,
    secureUserId,
    bp,
    isPersonalizedContentReady,
    surface,
    isAdmin
  });

  // NOTE: Register for you surface
  if (isServerSide) {
    registerForYouSurface({ id, contentConfig, customFields, surface });
  }
  React.useEffect(() => {
    if (isAdmin) {
      registerForYouSurface({ id, contentConfig, customFields, surface });
    }
  }, [
    isAdmin,
    id,
    contentConfig,
    customFields,
    surface,
    registerForYouSurface
  ]);

  const isClientSideContent = !!clientSideConfig;

  const isLiveTicker =
    customFields.liveTickerHide !== true && customFields.liveTickerNum > 0;

  // We want to automatically update feeds in the admin every three min.
  const pollInterval = type === "feed" || isLiveTicker ? 180000 : 0;
  const finalConfig = !isClientSideContent ? contentConfig : {};

  // fetch content from pcs
  let fetchedContent = useContent(
    Object.keys(finalConfig).length ? finalConfig : noContentConfig,
    pollInterval
  );

  const { useHook = () => useApi(), query, isReady } = clientSideConfig || {};

  // fetch content avoiding pcs
  const { content: clientSideContent, state: clientSideState } = useHook(
    query,
    isReady
  );
  if (isClientSideContent) {
    // Make sure { isLoading: true } is returned when loading
    fetchedContent = /ERROR|SUCCESS/.test(clientSideState)
      ? clientSideContent
      : { isLoading: true };
  }

  /**
   * START: Set Real-time Events (RTE) attributes (Part II) on the parent table.
   */
  const tableCtx = useTableLayoutContext();
  const { setAttrs = () => {} } = tableCtx;
  const moduleCategory = getRTEModuleCategory(
    clientSideContent?.items,
    chainCtx,
    layoutCtx
  );
  const includeFyAttrs =
    metaValue("contentType") === "front" && isClientSideContent;
  const attrsRTE = React.useMemo(
    () =>
      getRTEParentAttrs(
        clientSideContent?.items,
        surface,
        moduleCategory,
        modulePosition,
        includeFyAttrs
      ),
    [
      clientSideContent?.items,
      surface,
      moduleCategory,
      modulePosition,
      includeFyAttrs
    ]
  );

  React.useEffect(() => {
    setAttrs(attrsRTE);
  }, [attrsRTE, setAttrs]);
  // END: Set Real-time Events (RTE)

  switch (type) {
    case "feed":
      return (
        <Feed
          id={id}
          isAdmin={isAdmin}
          fetchedContent={fetchedContent}
          rootCustomFields={rootCustomFields}
          overrides={overrides}
          curationIndices={curationIndices}
          noGrid={noGrid}
        />
      );
    default:
      // NOTE: The StoryCards() call for the "cards" and default
      // ("no-backing-content") cases are the same, hence they both
      // occur here in the default case
      return (
        <StoryCards
          {...{
            id,
            index: 0,
            isAdmin,
            fetchedContent,
            rootCustomFields,
            overrides,
            curationIndices,
            noGrid
          }}
        />
      );
  }
};

FlexFeature.label = defaults.displayName;
FlexFeature.featurePlugins = ["presets"];
FlexFeature.propTypes = FlexFeaturePropTypes;
export default FlexFeature;
